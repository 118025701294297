import React from 'react';
import { ReactComponent as Barcelona } from '../img/barcelona.svg';
import { ReactComponent as Berlin } from '../img/berlin.svg';
import { ReactComponent as London } from '../img/london.svg';
import { ReactComponent as Poznan } from '../img/poznan.svg';
import { ReactComponent as Warzawa } from '../img/warzawa.svg';
import { ReactComponent as Commons } from '../img/commons.svg';
import { ReactComponent as Work } from '../img/work.svg';
import { ReactComponent as Data } from '../img/data.svg';
import { ReactComponent as Economy } from '../img/economy.svg';
import { ReactComponent as Space } from '../img/space.svg';
import { ReactComponent as Governance } from '../img/governance.svg';

import { FiToggleLeft } from 'react-icons/fi';
import { FiToggleRight } from 'react-icons/fi';
import { FiPackage } from 'react-icons/fi';
import { FiInfo } from 'react-icons/fi';
import { FiX } from 'react-icons/fi';
import { FiMaximize2 } from 'react-icons/fi';
import { FiExternalLink } from 'react-icons/fi';
import { GiPlainCircle } from 'react-icons/gi';

import './icons.css';

class Icons extends React.Component {
    static barcelona = <Barcelona />;    
    static berlin = <Berlin />;    
    static london = <London />;    
    static poznan = <Poznan />;    
    static warzawa = <Warzawa />;    

    static commons = <Commons />;    
    static work = <Work />;    
    static data = <Data />;    
    static economy = <Economy />;    
    static space = <Space />;    
    static governance = <Governance />;    
    
    static toggleLeftIcon = <FiToggleLeft />;
    static toggleRightIcon = <FiToggleRight />;
    static lockerIcon = <FiPackage />;
    static infoIcon = <FiInfo />;
    static closeIcon = <FiX />;
    static expandIcon = <FiMaximize2 />;
    static circleIcon = <GiPlainCircle />;
    static linkIcon = <FiExternalLink className='link' />;
}

export default Icons; 