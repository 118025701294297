import React, { useState, useEffect, createRef } from 'react';
import html2canvas from 'html2canvas';
import Audio from './audio/Audio.js';
import PDF from './pdf/PDF.js';
import Image from './image/Image.js';
import SlideShow from './slideshow/SlideShow.js';
import Text from './text/Text.js';
import Video from './video/Video.js';
import CaptionedVideo from './video/CaptionedVideo.js';
import LabeledIcon from '../../base/icons/LabeledIcon.js';
import { colorFromConcept } from '../../constants/converter'
import { iconFromElement } from '../../constants/converter';
import Colors from '../../constants/Colors.js';
import Icons from '../../constants/Icons.js';
import MediaTypes from '../../constants/MediaTypes.js';
import './abstractMedia.css'

export default function AbstractMedia(props) {
  const [position, setPosition] = useState(props.position);
  const element = props.element;

  const onScroll = (e) => {
    if (element.mediaType === MediaTypes.text || element.mediaType === MediaTypes.pdf) {
      setPosition(e.target.scrollTop);
    }
  }

  const scroller = createRef();
  
  useEffect(() => {
    if (element.mediaType === MediaTypes.text || element.mediaType === MediaTypes.pdf) {
      scroller.current.scrollTo({
        top: position,
        behavior: 'smooth'
      });
    }
  // eslint-disable-next-line
  }, [])

  const onPDFRenderSuccess = () => {
    scroller.current.scrollTo({
      top: position,
      behavior: 'smooth'
    })
  }
  
  const shouldImageBeSwapped = (element) => {    
    return element && (element.mediaType === MediaTypes.video || element.mediaType === MediaTypes.captionedVideo || element.mediaType === MediaTypes.audio);
  }

  const imageSource = (element) => {
    return element.mediaType === MediaTypes.audio ? element.name : element.content;
  }
  
  function onAddLockerItem(key, ref, name, position) {
    const options = shouldImageBeSwapped(element) ? { onclone: clone => swapVideo4PlaceholderImage(clone, imageSource(element)) } : {};
    html2canvas(ref, options)
      .then(canvas => {
        props.onAddLockerItem(key, canvas, name, position);
      })
      .catch(err => console.log(err));
  }

  function content(position, setPosition, mediaType, content, aspectRatio, caption) {    
    switch(mediaType) {
      case MediaTypes.audio: return <Audio content={ content } position={ position } onProgress={ setPosition } />;
      case MediaTypes.pdf: return <PDF content={ content } position={ position } onRenderSuccess={ onPDFRenderSuccess }/>;
      case MediaTypes.image: return <Image content={ content } caption={ caption }/>
      case MediaTypes.slideshow: return <SlideShow content={ content } caption={ caption } position={ position } onSlide={ setPosition } />;
      case MediaTypes.text: return <Text content={ content } position={ position } />;
      case MediaTypes.video: return <Video content={ content } aspectRatio={ aspectRatio } position={ position } onProgress={ setPosition } />;
      case MediaTypes.captionedVideo: return <CaptionedVideo content={ content } aspectRatio={ aspectRatio } caption={ caption } position={ position } onProgress={ setPosition } />
      default: return null;
    }
  }
      
  function swapVideo4PlaceholderImage(clone, content) {
    const newChild = document.createElement('div');
    newChild.classList.add('captionedImage');
    
    const firstGrandChild = document.createElement('div');
    firstGrandChild.classList.add('imageHolder');
    newChild.appendChild(firstGrandChild);
    
    const secondGrandChild = document.createElement('div');
    secondGrandChild.classList.add('caption');
    secondGrandChild.innerHTML = element.caption;
    newChild.appendChild(secondGrandChild);
    
    const newImage = document.createElement('img');
    newImage.classList.add('content');
    newImage.src = '/thumbnails/' + content.replace('?', '') + '.png';
    newImage.alt = '';
    firstGrandChild.appendChild(newImage);      
    
    const parent = clone.querySelector('.mediaMain');
    const child = parent.children[0];
    parent.replaceChild(newChild, child);
    
    // workaround for canvas promise returned prematurely
    return new Promise((resolve, __) => {
      setTimeout(() => { resolve() }, 500) 
    });
  }

  const ref = createRef(null)
  
  const headerIcons = element ? iconFromElement(element) : null;
  const headerTag = element ? element.group ? element.group : null : null;
  const container = element ? element.mediaType ? content(position, setPosition, element.mediaType, element.content, element.aspectRatio, element.caption) : null : null;
  const credits = element ? element.credits ? element.credits : null : null;  
  const color = element ? colorFromConcept(element.concept) : null;
  document.documentElement.style.setProperty('--variable-color', color);

  return (
    <div className='abstractMedia' ref={ ref }>
      <header className='mediaHeader'>
        <span>
          { headerIcons == null ? null : headerIcons.map((icon, index) => (            
            <LabeledIcon className='headerIcons'
                         key={ index }
                         icon={ icon }
                         onClick={ null }/>        
          ))}
          <span className='headerTag'>
            { headerTag }
          </span>
        </span>
        <div className='mediaHeaderBackWrapper'>
          <div className='mediaHeaderBack'>
            { credits }          
          </div>
        </div>
      </header>
      <main className='mediaMain' ref={ scroller } onScroll={ onScroll }>
        { container }
      </main>
      <footer className='mediaFooter'>
        <LabeledIcon className='addButton'
                     label='Add'
                     color={ Colors.white }
                     icon={ Icons.lockerIcon }
                     onClick={ () => onAddLockerItem(element.key, ref.current, element.url, position) }/>                
        <LabeledIcon className='closeButton'
                     label='Close'
                     color={ Colors.white }
                     icon={ Icons.closeIcon }
                     onClick={ () => props.onCloseModal(false) }/>                
      </footer>
    </div>         
  )
}