import React from 'react'
import Colors from '../../constants/Colors';
import { IconContext } from 'react-icons/lib'
import '../icons/labeledIcon.css'

export default function LabeledIcon(props) {
  const onClick = () => {
    if (props.onClick !== null) {
      props.onClick(props.label);
    }
  };

  const className = 'labeledIcon ' + props.className;
  const numberColor = { color : props.number === 0 ? 'var(--main-blue)' : 'var(--orange)' }

  return (
    <IconContext.Provider value={ { color: props.color, style: { strokeWidth : 1.5 } } }>
      <div className={ className } title={ props.title } style={ { display : + (props.hidden) ? 'none' : '' } } onClick={ onClick }>
        <span className="number" style={ numberColor }>{ props.number === 0 ? "" : props.number }</span>
        <span className={ props.iconClassName } style={ { color: props.color }}>{ props.icon }</span>
        <label className='label' style={ { color: Colors.white } }>{ props.label }</label>
      </div>
    </IconContext.Provider>
  );
}