import UIStrings from '../../constants/UIStrings'
import './title.css'

export default function Title(props) {
  return (
    <div className={ 'title' }>
      <div>{ UIStrings.title0 }</div>
      <div>{ UIStrings.title1 }</div>
      <div>{ UIStrings.title2 }</div>
    </div>
  );
}