import Map from './map/Map'
import Controls from '../controls/Controls'
import './main.css'

export default function Main(props) {
  return (
    <div className='main'>
      <Map selection={ props.selection }
           isMapVisible={ props.isMapVisible }
           data={ props.data }
           onOpenModal={ props.onOpenModal } />      
      <Controls onShowLocker={ props.onShowLocker }
                lockerItemNumber={ props.lockerItemNumber }
                isMapVisible={ props.isMapVisible }
                onMap={ props.onMap }
                onInfo={ props.onInfo } />
    </div>
  );
}