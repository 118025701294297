import React, { useState, useEffect } from 'react';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import { LockerDB, VisitedDB } from '../db/DB';
import Cities from '../../constants/Cities';
import Concepts from '../../constants/Concepts';
import UIStrings from '../../constants/UIStrings';
import Main from '../../main/Main';
import Title from '../../main/title/Title';
import Footer from '../../footer/Footer';
import { animated, useSpring } from '@react-spring/web'
import Info from '../../info/Info';
import Locker from '../../locker/Locker';
import Dialog from '../../main/dialog/Dialog';
import { ToastContainer, toast, Slide } from 'react-toastify';
import nodes from '../../data/Nodes';
import '../app/app.css';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  const cities = [Cities.barcelona, Cities.berlin, Cities.london, Cities.poznan, Cities.warzawa];
  const citySelection = new Map();
  let citiesSelected = 0;
  while (citiesSelected < Math.ceil(cities.length / 2)) {
    const selectedCity = Math.floor(Math.random() * cities.length);
    if (!citySelection.get(cities[selectedCity])) {
      citySelection.set(cities[selectedCity], true);
      citiesSelected++;
    }
  }

  const concepts = [Concepts.commons, Concepts.work, Concepts.data, Concepts.economy, Concepts.space, Concepts.governance];     
  const conceptSelection = new Map(); 
  let conceptsSelected = 0;
  while (conceptsSelected < Math.floor(cities.length / 2)) {
    const selectedConcept = Math.floor(Math.random() * concepts.length);
    if (!conceptSelection.get(concepts[selectedConcept])) {
      conceptSelection.set(concepts[selectedConcept], true);
      conceptsSelected++;
    }
  }

  var [selection, setSelection] = useState([citySelection, conceptSelection]);  
  var [isMapVisible, setIsMapVisible] = useState(false);
  var [isInfoVisible, setIsInfoVisible] = useState(false);
  var [isLockerShown, setIsLockerShown] = useState(false);
  var [isLockerExpanded, setIsLockerExpanded] = useState(false);
  var [isModalVisible, setIsModalVisible] = useState(false);
  var [position, setPosition] = useState(0);
  var [lockerItemNumber, setLockerItemNumber] = useState();

  useEffect(() => {
    updateLockerItemNumber();
  }, [lockerItemNumber]);

  const navigate = useNavigate();

  const onCity = (city, isSelected) => {
    if (!Array.from(selection[0].values()).includes(true)) {
      selection[1].forEach((__, key, map) => map.set(key, true));
    };
    setSelection([selection[0].set(city, isSelected), selection[1]]);
    if (!Array.from(selection[0].values()).includes(true)) {
      selection[1].forEach((__, key, map) => map.set(key, false));      
    };        
  }

  const onConcept = (concept, isSelected) => {
    if (!Array.from(selection[1].values()).includes(true)) {
      selection[0].forEach((__, key, map) => map.set(key, true));
    };
    setSelection([selection[0], selection[1].set(concept, isSelected)]);
    if (!Array.from(selection[1].values()).includes(true)) {
      selection[0].forEach((__, key, map) => map.set(key, false));      
    };        
  }

  const onMap = () => {
    setIsMapVisible(!isMapVisible);
  }

  const onInfo = () => {
    setIsInfoVisible(!isInfoVisible);
  }

  const onCloseInfo = () => {
    setIsInfoVisible(false);
  }

  const onShowLocker = () => {
    setIsLockerShown(!isLockerShown);
  }

  const onCloseLocker = () => {
    setIsLockerExpanded(false);
    setIsLockerShown(false);
  }

  const onExpandLocker = (isExpanded) => {
    setIsLockerExpanded(isExpanded);
  }
  
  const onCloseModal = (close) => {
    setIsModalVisible(close);
    navigate('/');
  }

  const onOpenModal = (node) => {
    setIsModalVisible(true);
    handleNodeVisit(node);
    navigate('/' + node.name);
  }

  async function handleNodeVisit(visitedNode) {
    VisitedDB.visitedNodes.put({ id: visitedNode.id });
  }

  async function onAddLockerItem(key, canvas, name, position) {
    const lockerItem = await LockerDB.lockerItems.where('key').equals(key).first();    
    await LockerDB.lockerItems.put({ key: key, image: canvas.toDataURL('image/png'), name: name, position: position });
    toast.info(lockerItem? UIStrings.snippetUpdated : UIStrings.snippetAdded);        
    updateLockerItemNumber();
  }

  async function onRemoveLockerItem(key) {    
    await LockerDB.lockerItems.delete(key)
    toast.info(UIStrings.snippetRemoved);
    updateLockerItemNumber();
  }

  async function onOpenLockerItem(key) {
    const lockerItem = await LockerDB.lockerItems.where('key').equals(key).first();    
    setPosition(lockerItem.position);
    updateLockerItemNumber();
    let elementToOpen = nodes.find(elem => elem.key === key);
    onOpenModal(elementToOpen);
  }

  async function updateLockerItemNumber() {
    const lockerItemNumber = await LockerDB.lockerItems.count();
    setLockerItemNumber(lockerItemNumber);
  }

  const onClick = (event) => {
    if (isLockerShown || isModalVisible) {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      if (isLockerShown) {
        onCloseLocker();
      }
    }
  }

  const slideUp = useSpring(isInfoVisible ? { y: '-100%' } : { y: '0' });

  return (
    <Routes>
      <Route path='/' element={ Parent() } >
        <Route path=':dialogUrl' element={ Child() } />
      </Route>
    </Routes>
  );

  function Child() {
    return(
      <Dialog data= { nodes }
              isModalVisible={ isModalVisible }
              position={ position } 
              onCloseModal={ onCloseModal } 
              onAddLockerItem={ onAddLockerItem } />
    )
  }

  function Parent() {
    return(
      <>
        <div>
          <>
            <div onClickCapture={ onClick }>
              <div className='mainGrid'>
                <Main selection={ selection } 
                      isMapVisible={ isMapVisible }
                      onMap={ onMap }
                      onInfo={ onInfo }
                      onShowLocker={ onShowLocker }
                      lockerItemNumber={ lockerItemNumber }
                      isModalVisible={ isModalVisible }
                      onOpenModal={ onOpenModal }
                      data={ nodes } />
                <Footer selection={ selection }
                        isMapVisible={ isMapVisible }
                        onCity={ onCity }
                        onConcept={ onConcept } />
              </div>
              <animated.div style={ slideUp }>
                <Info onCloseInfo={ onCloseInfo }/>
              </animated.div>            
            </div>
            <Title isModalVisible={ isModalVisible }
                   isLockerShown={ isLockerShown } />        
          </>
          <Locker isLockerShown={ isLockerShown }
                  onCloseLocker={ onCloseLocker }
                  isLockerExpanded={ isLockerExpanded }
                  onExpandLocker={ onExpandLocker }
                  onOpenLockerItem={ onOpenLockerItem }
                  onRemoveLockerItem={ onRemoveLockerItem } />                                
        </div>
        <ToastContainer toastClassName='toast'
                        position='bottom-right'
                        hideProgressBar={ true }
                        autoClose={ 3000 }
                        closeButton={ false }
                        pauseOnFocusLoss={ false }                         
                        transition={ Slide }/>            
        <Outlet />
      </>
    )
  }
}