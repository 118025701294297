import React from 'react';

class UIStrings extends React.Component {
    static title0 = 'STADT';
    static title1 = 'ALS';
    static title2 = 'BYTE';

    static dots = 'More';
    static locker = 'Locker';
    static map = 'Map';
    static text = 'Text';
    static mapOrText = 'Map\u00a0\u00a0Text';
    static info = 'Intro';

    static snippetUpdated = 'Snippet in locker updated!';
    static snippetAdded = 'Snippet added to locker!';
    static snippetRemoved = 'Snippet removed from locker!';

    static about = 'About'
    static howTo = 'How to'
    static credits = 'Credits';

    static disabledFooter = 'If you want to filter concepts or cities, please toggle to "' + UIStrings.text + '"';

    static empty = '';
}
export default UIStrings;