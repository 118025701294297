import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './slideShow.css'

export default function SlideShow(props) {  

  return(
    <div className='captionedSlideShow'>
      <ImageGallery className='slideShow'
                    items={ props.content } 
                    infinite={ true }
                    lazyLoad={ true }
                    showIndex={ true }
                    showNav={ true }
                    showThumbnails={ false }
                    showFullscreenButton={ true }
                    useBrowserFullscreen={ true }
                    showPlayButton={ false }
                    autoPlay={ false }
                    showBullets={ true }
                    startIndex={ props.position }
                    onSlide={ props.onSlide }/>
      <div className='caption'>{ props.caption }</div>
    </div>
  )
}