import React from 'react'
import UIStrings from '../constants/UIStrings'
import Colors from '../constants/Colors'
import Icons from '../constants/Icons'
import LabeledIcon from '../base/icons/LabeledIcon';
import './controls.css'

export default function Controls(props) {  
  return (
    <div className='controls'>        
      <LabeledIcon className='control'
                   iconClassName='controlsIcon'                     
                   icon={ props.isMapVisible ? Icons.toggleLeftIcon : Icons.toggleRightIcon }
                   label={ UIStrings.mapOrText }
                   color={ Colors.white }
                   onClick={ props.onMap } />
      <LabeledIcon className='control'
                   iconClassName='controlsIcon'                     
                   icon={ Icons.lockerIcon }
                   label={ UIStrings.locker }
                   number={ props.lockerItemNumber } 
                   color={ Colors.white } 
                   onClick={ props.onShowLocker } />
      <LabeledIcon className='control'
                   iconClassName='controlsIcon'                     
                   icon={ Icons.infoIcon } 
                   label={ UIStrings.info } 
                   color={ Colors.white } 
                   onClick={ props.onInfo } />        
      </div>
  );
}