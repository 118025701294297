import React from 'react';
import Colors from '../../constants/Colors'
import Icons from '../../constants/Icons'
import Cities from '../../constants/Cities'
import LabeledIcon from '../../base/icons/LabeledIcon'
import './cities.css'

export default function CitiesFooter(props) {
  const className = city => props.isMapVisible ? 'citySelected cityDisabled' : isSelected(city) ? 'citySelected' : 'city';
  const isSelected = city => props.selection[0].get(city);
  
  return (
    <div className='cities'>        
      <LabeledIcon className={ className(Cities.barcelona) }
                   iconClassName='footerIcon'                     
                   label={ Cities.barcelona } 
                   icon={ Icons.barcelona }
                   color={ Colors.white }
                   onClick={ label => props.onCity(label, !props.selection[0].get(label)) } />
      <LabeledIcon className={ className(Cities.berlin) }
                   iconClassName='footerIcon'
                   label={ Cities.berlin } 
                   icon={ Icons.berlin }
                   color={ Colors.white }
                   onClick={ label => props.onCity(label, !props.selection[0].get(label)) } />
      <LabeledIcon className={ className(Cities.london) }
                   iconClassName='footerIcon'
                   label={ Cities.london } 
                   icon={ Icons.london }
                   color={ Colors.white }
                   onClick={ label => props.onCity(label, !props.selection[0].get(label)) } />
      <LabeledIcon className={ className(Cities.poznan) }
                   iconClassName='footerIcon'
                   label={ Cities.poznan } 
                   icon={ Icons.poznan }
                   color={ Colors.white }
                   onClick={ label => props.onCity(label, !props.selection[0].get(label)) } />
      <LabeledIcon className={ className(Cities.warzawa) }
                   iconClassName='footerIcon'
                   label={ Cities.warzawa } 
                   icon={ Icons.warzawa }
                   color={ Colors.white }
                   onClick={ label => props.onCity(label, !props.selection[0].get(label)) } />
    </div>
  );
}  