import React from 'react';
import Colors from '../../constants/Colors'
import Icons from '../../constants/Icons'
import Concepts from '../../constants/Concepts'
import LabeledIcon from '../../base/icons/LabeledIcon'
import './concepts.css'

export default function ConceptsFooter(props) {
  const className = concept => props.isMapVisible ? 'conceptSelected conceptDisabled' : isSelected(concept) ? 'conceptSelected' : 'concept';
  const isSelected = concept => props.selection[1].get(concept);
  
  return (
    <div className='concepts'>
      <LabeledIcon className={ className(Concepts.commons) }
                   iconClassName='footerIcon'
                   label={ Concepts.commons }
                   icon={ Icons.commons } 
                   color={ Colors.commons } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
      <LabeledIcon className={ className(Concepts.work) }
                   iconClassName='footerIcon'
                   label={ Concepts.work }
                   icon={ Icons.work } 
                   color={ Colors.work } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
      <LabeledIcon className={ className(Concepts.data) }
                   iconClassName='footerIcon'
                   label={ Concepts.data }
                   icon={ Icons.data } 
                   color={ Colors.data } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
      <LabeledIcon className={ className(Concepts.economy) }
                   iconClassName='footerIcon'
                   label={ Concepts.economy }
                   icon={ Icons.economy } 
                   color={ Colors.economy } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
      <LabeledIcon className={ className(Concepts.space) }
                   iconClassName='footerIcon'
                   label={ Concepts.space }
                   icon={ Icons.space } 
                   color={ Colors.space } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
      <LabeledIcon className={ className(Concepts.governance) }
                   iconClassName='footerIcon'
                   label={ Concepts.governance }
                   icon={ Icons.governance } 
                   color={ Colors.governance } 
                   onClick={ label => props.onConcept(label, !props.selection[1].get(label)) } />
    </div>
  );
}