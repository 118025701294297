import Client from 'react-dom/client';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import App from './base/app/App';
import './index.css';

const container = document.getElementById('root')
const root = Client.createRoot(container);
const RoutedApp = () => (<StrictMode>
                           <Div100vh>
                             <BrowserRouter>
                               <App/>
                             </BrowserRouter>
                           </Div100vh>
                         </StrictMode>)
root.render(RoutedApp());