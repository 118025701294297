import Cities from './cities/Cities';
import Concepts from './concepts/Concepts';
import UIStrings from '../constants/UIStrings';
import './footer.css';

export default function Footer(props) {
  const selection = props.selection;
  const classNames = props.isMapVisible ? 'footer footerDisabled' : 'footer';
  const title = props.isMapVisible ? UIStrings.disabledFooter : null;
  
  return (
    <div className='footerContainer'>
      <div className={ classNames } title={ title }>
        <Concepts selection={ selection }                       
                  onConcept={ (concept, isSelected) => props.onConcept(concept, isSelected) }
                  isMapVisible = { props.isMapVisible  } />
        <Cities selection={ selection }
                onCity={ (city, isSelected) => props.onCity(city, isSelected) }
                isMapVisible = { props.isMapVisible  } />
      </div>
    </div>
  );
}